import { Button, DataGrid, DropDownButton } from "devextreme-react";
import { Column, ColumnChooser, DataGridRef, DataGridTypes, Editing, Export, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, LoadPanel, Scrolling, SearchPanel, Selection, Sorting, Toolbar } from "devextreme-react/cjs/data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import notify from "devextreme/ui/notify";
import { confirm } from "devextreme/ui/dialog";
import { appInfo } from "../../../app-info";
import { paymentsListSellRequest } from "../../../api/requests";



export const PaymentsListSell = () => {
  const gridRef = useRef<DataGridRef>(null);
  const {t} = useTranslation();
  const [listSell, setListSell] = useState([]);
  const [rowData, setRowData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);


  function getList(){
    gridRef.current?.instance().beginCustomLoading(t("loading"));
    paymentsListSellRequest().then((res)=>{
        if(res.resCode==200){
            setListSell(res.debtTracking || []);
        }else{
            notify(res.resMessage,"error");
        }
      gridRef.current?.instance().endCustomLoading();
    })
  }

  useEffect(()=>{
    getList();
  },[])

  const [isPanelOpened, setPanelOpened] = useState(false);

  const changeSelectProductPopup = useCallback(() => {
        setPanelOpened(!isPanelOpened);

}, [isPanelOpened]);

  const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
    setRowData(data);
  //  setPanelOpened(true);

  }, []);

  const onRowRemoving = (e) => {

  };

  const onSelectionChanged = (e) => {
    setSelectedRows(e.selectedRowsData);
};

async function deleteSelected(){

}

const handleContentReady = (e) => {
  setRowCount(e.component.totalCount());
};

  return (
    <div className="">
      <div className="">
      <DataGrid
          className=''
          focusedRowEnabled
          dataSource={listSell}
          keyExpr="invoiceNr"
          onRowClick={onRowClick}
          onExporting={onExporting}
          onRowRemoving={onRowRemoving}
          onSelectionChanged={onSelectionChanged}
          onContentReady={handleContentReady}
          noDataText={t("noData")}
          allowColumnReordering
          allowColumnResizing
          showBorders
          height={"80vh"}
          ref={gridRef}
          editing={{
            mode: 'row',
            allowDeleting: true,
        }}>
          <Editing texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}  />

          <LoadPanel />
          <SearchPanel width={250} visible placeholder={t("customerSearch")}  />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>


          <Item name='searchPanel' location='before'  />

          <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                hint={t("refresh")}
                stylingMode='text'
                onClick={getList}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
                <DropDownButton
                    visible={selectedRows.length>0}
                    text={"("+ selectedRows.length+") "+ t("transactions")}
                    icon="menu"
                    width={"175"}
                    items={[
                        { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                      ]}
                    onItemClick={(e) => {
                        if(e.itemData.action=="deleteSelected"){
                            deleteSelected();
                        }
                      }}
                />
            </Item>

            <Item location='center' locateInMenu='auto'>
                {rowCount} {t("records")}
            </Item>

            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                text={t("new")}
                type='default'
                stylingMode='contained'
                visible={true}
                onClick={changeSelectProductPopup}
              />
            </Item>

          </Toolbar>

          <Column dataField="dueDay" alignment="left"></Column>
            <Column dataField="dueDate"></Column>
            <Column dataField="invoiceDate"></Column>
            <Column dataField="invoiceNr"></Column>

            <Column dataField="customer"></Column>
            <Column dataField="shippingAdr" visible={false}></Column>
            <Column dataField="ducumentNr" visible={false}></Column>
            <Column dataField="invoiceType"></Column>
            <Column dataField="invoiceAmount"></Column>
            <Column dataField="coveredAmount"></Column>
            <Column dataField="remainingAmount"></Column>

        </DataGrid>
      </div>
    </div>
  );
};

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('ListSell.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ListSell.xlsx');
      });
    });
    e.cancel = true;
  }
};
