import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DataGrid, Column, Selection, Editing, Scrolling, SearchPanel, ColumnChooser, Export, HeaderFilter, Sorting, Toolbar, Item, DataGridTypes, FilterPanel, FilterBuilderPopup, DataGridRef } from 'devextreme-react/data-grid';
import { Button, DropDownButton, LoadPanel } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { appInfo } from '../../../app-info';
import { generalSegmentationCustomerDeleteRequest, generalSegmentationCustomerListRequest, generalSegmentationCustomerUpdateRequest } from '../../../api/requests';
import CustomerSearch from './searchCustomer';
import { confirm } from 'devextreme/ui/dialog';

const SegmentCustomerList = ({ segmentRef,readOnly, expandTable, setExpandTable }) => {
    const [customers, setCustomers] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const gridRef = useRef<DataGridRef>(null);
    const {t} = useTranslation();
    useEffect(() => {
        refresh();
    }, [segmentRef]);

    function refresh(){
      if(segmentRef != 0){
        gridRef.current!.instance().beginCustomLoading(t("loading"));
        generalSegmentationCustomerListRequest(segmentRef) // Fonksiyon adı düzeltilmiş
        .then((response) => {
            setCustomers(response.dataSet || []); // Null veya undefined durumlarına karşı korunma
        })
        .catch((error) => {
            console.error('Error fetching product list:', error); // Hata durumlarını loglamak için
        }).finally(()=>{
          gridRef.current!.instance().endCustomLoading();
        });
      }

    }


    const [isPanelOpened, setPanelOpened] = useState(false);


    const customerSearchPanelOpen = useCallback(() => {
        if(!readOnly){
            notify(t("saveAboveInformation"),"warning");
        }else{
            setPanelOpened(!isPanelOpened);
        }
    }, [isPanelOpened,readOnly]);

    const onRowUpdating = (e) => {

        const updatedData = { ...e.oldData, ...e.newData };
        console.log(updatedData)

        generalSegmentationCustomerUpdateRequest(updatedData).then((response) => {
            if (response.resCode === 200) {
                notify(t("updateSuccessfull"), "success", 1000);
            } else {
                notify(response["resMessage"], "error", 2500);
                refresh();
            }
        });



    };

    const onRowRemoving = (e) => {
        const _segmentCustomer = e.data;

        return generalSegmentationCustomerDeleteRequest(_segmentCustomer)
            .then((response) => {
                if (response.resCode === 200) {
                    notify(t("successfull"), "success", 1000);
                } else {
                    notify(response.resMessage, "error", 2500);
                    refresh();
                    throw new Error(response.resMessage);
                }
            })
            .catch((error) => {
                console.error('Error deleting product:', error);
            });
    };

     const onExporting = (e: DataGridTypes.ExportingEvent) => {
        if (e.format === 'pdf') {
          const doc = new JsPdf();
          exportDataGridToPdf({
            jsPDFDocument: doc,
            component: e.component,
          }).then(() => {
            doc.save('Products.pdf');
          });
        } else {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Page1');

          exportDataGridToXLSX({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'SegmentCustomers.xlsx');
            });
          });
          e.cancel = true;
        }
      };

      const onSelectionChanged = (e) => {
        setSelectedRows(e.selectedRowsData);
    };

      async function deleteSelected(){
      const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
        if (result) {
          gridRef.current?.instance().beginCustomLoading(t("loading"));
          var i = 0;
          selectedRows.forEach((row, index) => {

            generalSegmentationCustomerDeleteRequest(row).then((response)=>{
                  if(response["resCode"]==200){
                  }else{
                      notify(response["resMessage"],"error");
                  }
                  i++;
                  if(i==selectedRows.length){
                    gridRef.current?.instance().endCustomLoading();
                      refresh();
                  }
              });
          });
        }
    }

    function expandTableFunc(){
      if(expandTable){
        setExpandTable(false)
      }else{
        setExpandTable(true)
      }
    }

    return (
        <>
        <div style={{height:"100%"}}>
        <div style={{flex:1, overflowY:"visible"}}>
            <DataGrid
            className='custom-grid'
            dataSource={customers}
            ref={gridRef}
            onRowUpdating={onRowUpdating}
            onRowRemoving={onRowRemoving}
            onExporting={onExporting}
            onSelectionChanged={onSelectionChanged}
            rowAlternationEnabled
            keyExpr="segmentCustomerRef"
            noDataText={t("noData")}
            height={expandTable?"calc(95vh - 140px)":"calc(95vh - 315px)"}
            editing={{
                mode: 'row',
                allowUpdating: true,
                allowDeleting: true,
                allowAdding: false,
            }}

        >
        <Toolbar>
        <Item name='searchPanel' location='before'  />

        <Item location='before' locateInMenu='auto'>
                    <Button
                            icon='refresh'
                            stylingMode='text'
                            hint={t("refresh")}
                            onClick={refresh}
                        />
        </Item>

        <Item name='columnChooserButton' location='before' />

        <Item name='exportButton' location='before' />

        <Item location="before">
                      <DropDownButton
                      visible={selectedRows.length>0}
                        text={t("transactions")}
                        icon="menu"
                        width={"150"}
                        items={[
                          { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                        ]}
                        onItemClick={(e) => {
                          if(e.itemData.action=="deleteSelected"){
                            deleteSelected();
                          }
                        }}
                      />
                    </Item>

        <Item location='after' locateInMenu='auto'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
                        icon="plus"
                        type="default"
                        stylingMode="contained"
                        hint={t("selectCustomers")}
                        onClick={customerSearchPanelOpen}
                        disabled={!readOnly}

                    />
                    <div style={{ marginLeft: '10px' }}>
                            <Button
                                icon={expandTable?"chevrondown":"fullscreen"}
                                type="default"
                                hint={t("expandTable")}
                                stylingMode="contained"
                                onClick={expandTableFunc}
                                disabled={!readOnly}
                            />
                            </div>
                    </div>
        </Item>

        </Toolbar>
        <LoadPanel showPane={false}  />
        <SearchPanel visible placeholder={t("search")} width={"300px"} />
        <ColumnChooser  enabled  title={t("columnChooser")}/>
        <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
        <HeaderFilter visible />
        <Sorting mode='multiple' />
        <Scrolling mode='virtual' />
        <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
        <FilterPanel visible={true} />
        <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />
        <Editing
        texts={{
            editRow: t("edit"),
            deleteRow: t("delete"),
            confirmDeleteMessage: t("confirmDeleteMessage"),
            confirmDeleteYes: t("yes"),
            cancelRowChanges: t("cancel"),
            saveRowChanges: t("save")
        }}/>

            <Column dataField='customerCode' caption={t("customerCode")} width={130} allowEditing={false} />
            <Column dataField='customerName' caption={t("customerName")} allowEditing={false}/>
            <Column dataField='customerAdr' caption={t("erpCustomerAdr")}  allowEditing={false} />
            <Column dataField='shippingAdr' caption={t("erpShippingAdr")}  allowEditing={false}/>
            <Column dataField='comment'  caption={t("comment")} />

    </DataGrid>

        </div>

        </div>
        <CustomerSearch customersOnCampaign={customers} visible={isPanelOpened} onHiding={customerSearchPanelOpen} segmentRef={segmentRef} refresh={refresh}></CustomerSearch>

    </>
    );
};

export default SegmentCustomerList;
