import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import From, { Item as ItemForm, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import { FormTextbox } from '../../utils/form-textbox/FormTextbox';
import { CustomerModel } from '../../../types/crm-contact';
import { Button, CheckBox, TextBox } from 'devextreme-react';
import { customerGroupsRequest, customerUpdateRequest } from '../../../api/requests';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';



export const ContactFromDetails = ({mainRefresh,closePopup, data, editing, updateField }: {mainRefresh, closePopup
  data: CustomerModel, editing: boolean, updateField: (field: string | number) => (value: string | number) => void
}) => {
  const {t} = useTranslation();
  const [cGroups, setCGroups] = useState([]);
  const [customerGroupRef, setCustomerGroupRef] = useState(data.customerGroupRef);
  const [status, setStatus] = useState(data.customerActive==1?true:false);
  const [readOnly, setReadOnly] = useState(true);
  const [customer,setCustomer] = useState(data);
  const [customerState,setCustomerState] = useState(data);

  useEffect(()=>{
    setReadOnly(true);
    setCustomer(data)
    customerGroupsRequest().then((res)=>{
      setCGroups(res.data.dataSet);
      console.log(cGroups)
    })
    setCustomerGroupRef(data.customerGroupRef);
  },[data.customerGroupRef])


  const handleOnChangeCG = (event) => {
    setCustomerGroupRef(event.value);
  };


  var updateCustomer = async () => {

    try {
     customerUpdateRequest(
      customer.customerRef,
      customer.customerActive,
      customer.customerCode,
      customer.customerName,
      customerGroupRef,
      customer.erpCustomerRef,
      customer.erpShippingAdrRef,
      customer.productGroupRef
      ).then((res)=>{
        if(res.resCode==200){
          notify(t("successfull"),"success");
          mainRefresh();
          setReadOnly(true)
          setCustomerState(customer)
        }else{
          notify(res.resMessage,"error");
        }
      });
    } catch (error) {
      console.error('API hatası:', error);
    }

  };

  const navigate = useNavigate();

  const updateFieldd = (key) => (value) => {
    setCustomer(prev => ({
      ...prev,
      [key]: value
    }));
  };

  return (
    <From
      className={classNames({ 'plain-styled-form': true, 'view-mode': !editing })}
      labelMode='floating'
    >
      <GroupItem >

      <ItemForm visible={!readOnly}>
        <div style={{display:"flex",justifyContent:"flex-end"}}>
          <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={updateCustomer} />
        <div style={{ marginLeft: '10px' }}>
        <Button
                      text={t("cancel")}
                      icon='close'
                      stylingMode='contained'
                      style={{backgroundColor:"gray"}}
                      type='default'
                      onClick={()=>{
                        setReadOnly(true)
                        setCustomer(customerState)
                      }}
                    />
        </div>
        </div>
          </ItemForm>

          <ItemForm visible={readOnly}>
        <div style={{display:"flex",justifyContent:"flex-end"}}>
          <Button text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={()=>{setReadOnly(false)}} />
        <div style={{ marginLeft: '10px' }}>
          <Button text={t("close")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={closePopup} />
        </div>
        </div>
          </ItemForm>


        <ColCountByScreen xs={1} />


        <GroupItem>
          <ItemForm>
          <CheckBox
              value={customer.customerActive==1}
              text={t("active")}
              onValueChanged={(e) => updateFieldd('customerActive')(e.value?1:0)}
              readOnly={readOnly}
            />
          </ItemForm>

          <ItemForm>
            <TextBox
              label={t("customerCode")}
              value={customer.customerCode}
              readOnly={readOnly}
              onValueChange={updateFieldd('customerCode')}

            />
          </ItemForm>

          <ItemForm>
            <TextBox
              label={t("customerName")}
              value={customer.customerName}
              readOnly={readOnly}
              onValueChange={updateFieldd('customerName')}
            />
          </ItemForm>

          <ItemForm >
                      <SelectBox
                      label={t("customerGroup")}
                       id="customerGroups"
                       value={customerGroupRef&&customerGroupRef}
                       dataSource={cGroups}
                       displayExpr="customerGroupName"
                       valueExpr="customerGroupRef"
                       onValueChanged={handleOnChangeCG}
                       readOnly={readOnly}
                       />
                    </ItemForm>
        </GroupItem>

        <ItemForm>
          <div style={{height:15}}></div>
         <div ><hr></hr></div>
        </ItemForm>

        <ItemForm>
         <b>{t("erpCustomerInfo")}</b>
         <p>{customer.erpCustomerCode}</p>
         <p>{customer.erpCustomerName1}</p>
         <p>{customer.erpCustomerAddr1}</p>
         <p>{customer.erpCustomerPostCode} {customer.erpCustomerCity}</p>
        </ItemForm>

        <ItemForm>
         <div><hr></hr></div>
        </ItemForm>

        <ItemForm>
         <b>{t("erpShippingInfo")}</b>
         <p>{customer.erpShippingAdrCode}</p>
         <p>{customer.erpShippingAdrName1}</p>
         <p>{customer.erpShippingAdrAddr1}</p>
         <p>{customer.erpShippingAdrPostCode} {customer.erpShippingAdrCity}</p>
        </ItemForm>


      </GroupItem>


    </From>
  );
};
