import React, {useRef } from 'react';
import Chart, { Series, Label, Legend, Size, Format, ValueAxis, Tooltip } from 'devextreme-react/chart';
import { CardAnalytics } from '../../library/card-analytics/CardAnalytics';
import { euroFormat } from '../../../utils/format-currency';

const ReturnsGraph = ({returnsData,title})=> {

  return (

<div id="chart-demo">
      <div className="charts">
      <CardAnalytics title={title} contentClass='sales-by-category'>
        <Chart
          id="chart"
          dataSource={returnsData}
          rotated={true}
        >
          <Series
            type="bar"
            argumentField="lineName"
            valueField="lineTotal"
            color="#ff4444"
          >
            <Label visible={true} />
          </Series>
          <Legend visible={false} />
          <ValueAxis>
        <Label>
          <Format type='currency' currency='EUR' />
        </Label>
      </ValueAxis>
          <Size height={270} />
          <Tooltip format={euroFormat}></Tooltip>
        </Chart>
        </CardAnalytics>

      </div>

    </div>


  );
}

export default ReturnsGraph;
