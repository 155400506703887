import React, { useState, useEffect, useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import DataGrid, { Column, ColumnChooser, DataGridRef, DataGridTypes, Export, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, LoadPanel, Scrolling, SearchPanel, Selection,Toolbar  } from 'devextreme-react/data-grid';
import { b2bUsersListRequest, b2bUsersListUpdateWithCustomerRequest, b2bUsersListUpdateWithCustomerRequest2, } from '../../../api/requests';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { appInfo } from '../../../app-info';

const B2BCustomerSearchPopup = ({refresh, visible, onHiding, customer }) => {
    const [users, setUsers] = useState([]);
    const {t} = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]); // Seçili ürünlerin yönetimi için
    const gridRef = useRef<DataGridRef>(null);
    const [totalCount, setTotalCount]= useState(0);

    useEffect(() => {
        if (visible) {
          getListFromApi();
        }
        clearSelections();
      }, [visible]);


    function getListFromApi(){
      gridRef.current!.instance().beginCustomLoading(t("loading"));
      b2bUsersListRequest({customerRef: customer.customerRef}).then(data => {
          if(data.resCode==200){
          setUsers(data.dataSet);
          setTotalCount(data.dataSet&&data.dataSet.length);
          }else{
            notify(data.resMessage,"error");
          }

        }).finally(()=>{
          gridRef.current!.instance().endCustomLoading();
        });
    }

    const onAddButtonClick = () => {
        gridRef.current?.instance().beginCustomLoading(t("loading"));
        var i =0;
        selectedRows.forEach((row, index) => {

            b2bUsersListUpdateWithCustomerRequest2(row,customer).then((response)=>{
                if(response["resCode"]==200){
                    notify(row["customerName"]+" eklendi","success");
                }else{
                    notify(response["resMessage"],"error");
                }
                i++;
                if(i==selectedRows.length){
                    gridRef.current?.instance().endCustomLoading();
                    onHiding();
                    clearSelections();
                    refresh();
                }
            });
        });


    };

    const clearSelections = () => {
        setSelectedRows([])
        gridRef.current?.instance().clearFilter();
        gridRef.current?.instance().clearSelection();
    };


    const onSelectionChanged = (e) => {
        setSelectedRows(e.selectedRowsData); // Seçili satırları güncelle
    };

    const onExporting = (e: DataGridTypes.ExportingEvent) => {
        if (e.format === 'pdf') {
          const doc = new JsPdf();
          exportDataGridToPdf({
            jsPDFDocument: doc,
            component: e.component,
          }).then(() => {
            doc.save('Products.pdf');
          });
        } else {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Page1');

          exportDataGridToXLSX({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Customers.xlsx');
            });
          });
          e.cancel = true;
        }
      };

    return (
        <Popup
          visible={visible}
          onHiding={onHiding}
          title={t("users")}
          width={"65%"}
          height={"85%"}
          dragEnabled={true}
          titleRender={()=>(
            <div className="flex flex-row justify-between">
              <div className="flex flex-row">
              <Button icon="close" stylingMode="text" onClick={onHiding}></Button>
              <p className="ml-2 mt-0 justify-center font-bold text-lg">{t("users")}</p>
              </div>
              <div className="flex flex-row gap-x-1">

              <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                    icon='check'
                    text={t("select")}
                    type='default'
                    stylingMode='contained'
                    visible={true}
                    onClick={onAddButtonClick}
                    disabled={!(selectedRows.length>0)}
                    />
                    <div style={{ marginLeft: '10px' }}>
                      <Button
                      text={t("close")}
                      icon='close'
                      stylingMode='contained'
                      style={{backgroundColor:"gray"}}
                      type='default'
                      onClick={onHiding}
                    />
                    </div>
                  </div>

                </div>
            </div>
          )}
          showCloseButton={true}

        >


                <DataGrid
                ref={gridRef}
                dataSource={users}
                keyExpr="userRef"
                onExporting={onExporting}
                onSelectionChanged={onSelectionChanged}
                showBorders={true}
                noDataText={t("noData")}
                columnAutoWidth={false}
                allowColumnReordering
                height={"100%"}
            >

                <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
                <LoadPanel showPane={false}  />
                <SearchPanel placeholder={t("search")}  searchVisibleColumnsOnly={false} width={"300px"} visible={true} />
                <ColumnChooser title={t("columnChooser")} enabled />
                <Selection  mode='multiple' />
                <HeaderFilter visible />
                <Scrolling columnRenderingMode='virtual' mode='virtual' />
                <LoadPanel showPane={true}  />
                <FilterPanel visible={true} />
                <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

                <Toolbar>

                <Item name='searchPanel' location='before'  />

                <Item location='before' locateInMenu='auto'>
                <Button
                        icon='clearformat'
                        hint={t("clearFilters")}
                        stylingMode='text'
                        onClick={clearSelections}
                    />
                </Item>


                <Item location='before' locateInMenu='auto'>
                <Button
                        icon='refresh'
                        stylingMode='text'
                        hint={t("refreshList")}
                        onClick={getListFromApi}
                    />
                </Item>

                <Item location='before' name='columnChooserButton' locateInMenu='auto' />

                <Item name='exportButton' location='before' />

                <Item location='center' locateInMenu='auto'>
                <p>{t("selected")}: {selectedRows.length} / {totalCount}</p>
                </Item>

                </Toolbar>


                <Column dataField="userName" caption={t("name")} />
                <Column dataField="userEmail" caption={t("email")} />
                <Column dataField="comment" caption={t("comment")} />


            </DataGrid>
        </Popup>
    );
};

export default B2BCustomerSearchPopup;
