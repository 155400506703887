import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DataGrid, Column, DataGridTypes, Editing, Scrolling, SearchPanel, ColumnChooser, Export, HeaderFilter, Sorting, Selection, Toolbar, Item, FilterPanel, FilterBuilderPopup, DataGridRef } from 'devextreme-react/data-grid';
import { Button, DropDownButton, LoadPanel } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { appInfo } from '../../../app-info';
import { generalSegmentationProductGroupsDeleteRequest, generalSegmentationProductGroupsListRequest, generalSegmentationProductGroupsUpdateRequest, } from '../../../api/requests';
import ProductGroupSearch from './searchProductGroup';
import { confirm } from 'devextreme/ui/dialog';


const SegmentProductGroupsList = ({ segmentRef, readOnly, expandTable, setExpandTable }) => {
    const [productGroups, setProductGroups] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const gridRef = useRef<DataGridRef>(null);
    const {t} = useTranslation();

    function getList() {
      if(segmentRef != 0){
        gridRef.current!.instance().beginCustomLoading(t("loading"));
        generalSegmentationProductGroupsListRequest(segmentRef)
        .then((response) => {
           // state.data = response.dataSet;
            setProductGroups(response.dataSet || []);
        })
        .catch((error) => {
            console.error('Error fetching product list:', error);
        }).finally(()=>{
          gridRef.current!.instance().endCustomLoading();
        });
      }
    }

    useEffect(() => {
        setProductGroups([]);
        getList();
    }, [segmentRef]);

    const onRowUpdating = (e) => {

        const updatedData = { ...e.oldData, ...e.newData };

        e.newData = updatedData;

        generalSegmentationProductGroupsUpdateRequest(updatedData).then((response) => {
            if (response.resCode == 200) {
                notify(t("updateSuccessfull"), "success", 1000);
            } else {
                notify(response["resMessage"], "error", 2500);
                getList();
            }
        });

    };

    const onRowRemoving = (e) => {
        const _segmentProductGroup = e.data;

        return generalSegmentationProductGroupsDeleteRequest(_segmentProductGroup)
            .then((response) => {
                if (response.resCode == 200) {
                    notify(t("successfull"), "success", 1000);
                } else {
                    notify(response.resMessage, "error", 2500);
                    getList();
                    throw new Error(response.resMessage);
                }
            })
            .catch((error) => {
                console.error('Error deleting product:', error);
            });

    };

    const [isPanelOpened, setPanelOpened] = useState(false);

    const changeSelectProductPopup = useCallback(() => {
        if(!readOnly){
            notify(t("saveAboveInformation"),"warning");
        }else{
            setPanelOpened(!isPanelOpened);
        }
    }, [isPanelOpened,readOnly]);



    const onExporting = (e: DataGridTypes.ExportingEvent) => {
        if (e.format === 'pdf') {
          const doc = new JsPdf();
          exportDataGridToPdf({
            jsPDFDocument: doc,
            component: e.component,
          }).then(() => {
            doc.save('ProductGroups.pdf');
          });
        } else {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Page1');

          exportDataGridToXLSX({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'SegmentProductGroups.xlsx');
            });
          });
          e.cancel = true;
        }
      };

      const onSelectionChanged = (e) => {
        setSelectedRows(e.selectedRowsData);
    };

      async function deleteSelected(){
        const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
          if (result) {
            gridRef.current?.instance().beginCustomLoading(t("loading"));
            var i = 0;
            selectedRows.forEach((row, index) => {

                generalSegmentationProductGroupsDeleteRequest(row).then((response)=>{
                    if(response["resCode"]==200){
                    }else{
                        notify(response["resMessage"],"error");
                    }
                    i++;
                    if(i==selectedRows.length){
                        gridRef.current?.instance().endCustomLoading();
                        getList();
                    }
                });
            });
          }
      }

      function expandTableFunc(){
        if(expandTable){
          setExpandTable(false)
        }else{
          setExpandTable(true)
        }
      }

    return (
        <>
            <div>
                <DataGrid
                className='custom-grid'
                    dataSource={productGroups}
                    ref={gridRef}
                    allowColumnReordering
                    keyExpr="segmentProductGroupRef"
                    onExporting={onExporting}
                    onRowUpdating={onRowUpdating}
                    onRowRemoving={onRowRemoving}
                    onSelectionChanged={onSelectionChanged}
                    allowColumnResizing
                    rowAlternationEnabled
                    noDataText={t("noData")}
                    repaintChangesOnly
                    height={expandTable?"calc(95vh - 140px)":"calc(95vh - 315px)"}
                            >
                    <Toolbar>
                    <Item name='searchPanel' location='before'  />

                    <Item location='before' locateInMenu='auto'>
                    <Button
                            icon='refresh'
                            stylingMode='text'
                            hint={t("refresh")}
                            onClick={getList}
                        />
                    </Item>

                    <Item name='columnChooserButton' location='before' />

                    <Item name='exportButton' location='before' />

                    <Item location="before">
                      <DropDownButton
                      visible={selectedRows.length>0}
                        text={t("transactions")}
                        icon="menu"
                        width={"150"}
                        items={[
                          { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                        ]}
                        onItemClick={(e) => {
                          if(e.itemData.action=="deleteSelected"){
                            deleteSelected();
                          }
                        }}
                      />
                    </Item>

                    <Item location='after' locateInMenu='auto'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                            icon="plus"
                            type="default"
                            stylingMode="contained"
                            hint={t("selectProductGroups")}
                            onClick={changeSelectProductPopup}
                            disabled={!readOnly}
                            />
                            <div style={{ marginLeft: '10px' }}>
                            <Button
                                icon={expandTable?"chevrondown":"fullscreen"}
                                type="default"
                                hint={t("expandTable")}
                                stylingMode="contained"
                                onClick={expandTableFunc}
                                disabled={!readOnly}
                            />
                            </div>
                        </div>

                    </Item>

                    </Toolbar>


                    <LoadPanel showPane={false}  />
                    <SearchPanel visible placeholder={t("search")} width={"300px"} />
                    <ColumnChooser  enabled  title={t("columnChooser")}/>
                    <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
                    <HeaderFilter visible />
                    <Sorting mode='multiple' />
                    <Scrolling mode='virtual' />
                    <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
                    <FilterPanel visible={true} />
                    <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />


                    <Editing
                    mode="row"
                    allowDeleting
                    allowUpdating
                    texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}
                    />

            <Column dataField='productGroupCode' caption={t("name")}  allowEditing={false}/>
            <Column dataField='comment' caption={t("comment")}  />

                </DataGrid>
            </div>
            <ProductGroupSearch visible={isPanelOpened} onHiding={changeSelectProductPopup} segmentRef={segmentRef} getList={getList} />

        </>
    );
};

export default SegmentProductGroupsList;
