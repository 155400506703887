import React, { useEffect, useState } from 'react';

import TreeList, {
  Column, ColumnChooser, HeaderFilter, SearchPanel, Selection, Lookup,
} from 'devextreme-react/tree-list';




export const ImageTest= () => {

  return (
    <TreeList
      showBorders={true}
      columnAutoWidth={true}
      wordWrapEnabled={true}
      keyExpr="itemSubCategoryName"
      parentIdExpr="itemCategoryName"
      id='asd'
    >
      <SearchPanel visible={true} width={250} />
      <HeaderFilter visible={true} />
      <Selection mode="multiple" />
      <ColumnChooser enabled={true} />
    <Column dataField='itemCategoryName'></Column>

    </TreeList>
  );
}

