import React, { useState, useEffect, useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import DataGrid, { Column, ColumnChooser, DataGridRef, DataGridTypes, Export, FilterBuilderPopup, FilterPanel, HeaderFilter, Item, LoadPanel, Scrolling, SearchPanel, Selection,Toolbar  } from 'devextreme-react/data-grid';
import { campaignCustomerSearchRequest, campaignCustomerSelectRequest, generalSegmentationCustomerInsertRequest, segmentCustomerSearchListRequest } from '../../../api/requests';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { appInfo } from '../../../app-info';

const CustomerSearch = ({customersOnCampaign, visible, onHiding, segmentRef, refresh }) => {
    const [customers, setCustomers] = useState([]);
    const {t} = useTranslation();
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const gridRef = useRef<DataGridRef>(null);
    const [totalCount, setTotalCount]= useState(0);

    useEffect(() => {
        if (visible) {
          getListFromApi();
        }
        clearSelections();
      }, [visible]);


    function getListFromApi(){
      gridRef.current?.instance().beginCustomLoading(t("loading"))
      segmentCustomerSearchListRequest(segmentRef).then(data => {
        if(data.resCode==200){
          const updatedData = data.dataSet.map(item => ({
            ...item,
            customerKey: `${item.erpCustomerRef}-${item.erpShippingAdrRef}`
        }));
        setCustomers(updatedData);
        setTotalCount(data.dataSet&&data.dataSet.length);
        }else{
          notify(data.resMessage,"error")
        }
        }).finally(()=>{
          gridRef.current?.instance().endCustomLoading();
        });
    }


    const onAddButtonClick = () => {
      gridRef.current?.instance().beginCustomLoading(t("loading"));
      var i =0;
      selectedCustomers.forEach((customer, index) => {

        generalSegmentationCustomerInsertRequest(segmentRef,customer).then((response)=>{
              if(response["resCode"]==200){
                  notify(customer["customerName"]+" eklendi","success");
                 // selectedCustomers.splice(index,1);
              }else{
                  notify(response["resMessage"],"error");
              }
              i++;
              if(i==selectedCustomers.length){
                gridRef.current?.instance().endCustomLoading();
                  onHiding();
                  refresh();
                  clearSelections();
              }
          });
      });
    };

    const clearSelections = () => {
        setSelectedCustomers([])
        gridRef.current?.instance().clearFilter();
        gridRef.current?.instance().clearSelection();
    };


    const onSelectionChanged = (e) => {
        setSelectedCustomers(e.selectedRowsData); // Seçili satırları güncelle
    };

    const onExporting = (e: DataGridTypes.ExportingEvent) => {
        if (e.format === 'pdf') {
          const doc = new JsPdf();
          exportDataGridToPdf({
            jsPDFDocument: doc,
            component: e.component,
          }).then(() => {
            doc.save('Products.pdf');
          });
        } else {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Page1');

          exportDataGridToXLSX({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Customers.xlsx');
            });
          });
          e.cancel = true;
        }
      };

    return (
        <Popup
          visible={visible}
          onHiding={onHiding}
          title={t("customers")}
          width={"85%"}
          height={"85%"}
          dragEnabled={true}
          titleRender={()=>(
            <div className="flex flex-row justify-between">
              <div className="flex flex-row">
              <Button icon="close" stylingMode="text" onClick={onHiding}></Button>
              <p className="ml-2 mt-0 justify-center font-bold text-lg">{t("customers")}</p>
              </div>
              <div className="flex flex-row gap-x-1">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                    icon='check'
                    text={t("select")}
                    type='default'
                    stylingMode='contained'
                    visible={true}
                    onClick={onAddButtonClick}
                    disabled={!(selectedCustomers.length>0)}
                    />
                    <div style={{ marginLeft: '10px' }}>
                      <Button
                      text={t("close")}
                      icon='close'
                      stylingMode='contained'
                      style={{backgroundColor:"gray"}}
                      type='default'
                      onClick={onHiding}
                    />
                    </div>
                  </div>
                </div>
            </div>
          )}
          showCloseButton={true}

        >


                <DataGrid
                ref={gridRef}
                dataSource={customers}
                keyExpr="customerKey"
                onExporting={onExporting}
                onSelectionChanged={onSelectionChanged}
                showBorders={true}
                noDataText={t("noData")}
                columnAutoWidth={false}
                allowColumnReordering
                height={"100%"}
            >

                <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
                <LoadPanel showPane={false}  />
                <SearchPanel placeholder={t("search")}  searchVisibleColumnsOnly={false} width={"300px"} visible={true} />
                <ColumnChooser title={t("columnChooser")} enabled />
                <Selection  mode='multiple' />
                <HeaderFilter visible />
                <Scrolling columnRenderingMode='virtual' mode='virtual' />
                <LoadPanel showPane={true}  />
                <FilterPanel visible={true}/>
                <FilterBuilderPopup width={"50%"} height={"50%"} />

                <Toolbar>

                <Item name='searchPanel' location='before'  />

                <Item location='before' locateInMenu='auto'>
                <Button
                        icon='clearformat'
                        hint={t("clearFilters")}
                        stylingMode='text'
                        onClick={clearSelections}
                    />
                </Item>

                <Item location='before' locateInMenu='auto'>
                <Button
                        icon='refresh'
                        stylingMode='text'
                        hint={t("refreshList")}
                        onClick={getListFromApi}
                    />
                </Item>


                <Item location='before' name='columnChooserButton' locateInMenu='auto' />

                <Item name='exportButton' location='before' />

                <Item location='center' locateInMenu='auto'>
                <p>{t("selected")}: {selectedCustomers.length} / {totalCount}</p>
                </Item>


                </Toolbar>


                <Column dataField="customerCode" caption={t("customerCode")} width={150}/>
                <Column dataField="customerName" caption={t("customerName")} />
                <Column dataField="customerAdr" caption={t("invoiceAddress")} />
                <Column dataField="shippingAdr" caption={t("shippingAddress")} />

                <Column dataField="customerCountry" caption={t("country")}  visible={false} />
                <Column dataField="shippingCountry" visible={false} />
                <Column dataField="shippingAdrName"  visible={false} />
                <Column dataField="speCode" caption={t("specCode1")}  visible={false} />
                <Column dataField="speCode2" caption={t("specCode2")}  visible={false} />
                <Column dataField="speCode3" caption={t("specCode3")}  visible={false} />
                <Column dataField="speCode4" caption={t("specCode4")}  visible={false} />
                <Column dataField="specCode5" caption={t("specCode5")}  visible={false} />


            </DataGrid>
        </Popup>
    );
};

export default CustomerSearch;
