import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import From, { Item as ItemForm, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { CustomerUserModel } from '../../../types/crm-contact';
import { Button, CheckBox, TextBox } from 'devextreme-react';
import { b2bUsersListUpdateRequest, customerGroupsRequest, customerUsersRequest, customerUserUpdateRequest } from '../../../api/requests';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import { Eye, EyeOff } from 'react-feather';


export const ContactFromDetails = ({mainRefresh,closePopup, data, editing, updateField }: {mainRefresh, closePopup,
  data: CustomerUserModel, editing: boolean, updateField: (field: string | number) => (value: string | number) => void
}) => {
  const {t} = useTranslation();
  const [cGroups, setCGroups] = useState([]);
  const [status, setStatus] = useState(data.userActive==1?true:false);
  const [readOnly, setReadOnly] = useState(true);
  const [user,setUser] = useState(data);
  const [userState,setUserState] = useState(data);

  useEffect(()=>{
    setReadOnly(true);
    setUser(data);
    customerGroupsRequest().then((res)=>{
      setCGroups(res.data.dataSet);
      console.log(cGroups)
    })
  },[data])

  const updateFieldd = (key) => (value) => {
    setUser(prev => ({
      ...prev,
      [key]: value
    }));
  };

  var updateCustomer = async () => {

    b2bUsersListUpdateRequest(user).then((res)=>{
      if(res.resCode==200){
        notify(t("successfull"),"success")
        mainRefresh();
        setUserState(user)
        setReadOnly(true)
      }else{
        notify(res.resMessage,"error")
      }
    })
  };

  const passwordEditorOptions = { stylingMode: 'filled', placeholder:  t('password'), mode: 'password' };
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <From
      className={classNames({ 'plain-styled-form': true, 'view-mode': !editing })}
      labelMode='floating'
    >
      <GroupItem >

      <ItemForm visible={!readOnly}>
        <div style={{display:"flex",justifyContent:"flex-end"}}>
          <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={updateCustomer} />
        <div style={{ marginLeft: '10px' }}>
        <Button
                      text={t("cancel")}
                      icon='close'
                      stylingMode='contained'
                      style={{backgroundColor:"gray"}}
                      type='default'
                      onClick={()=>{
                        setReadOnly(true)
                        setUser(userState)
                      }}
                    />
        </div>
        </div>
          </ItemForm>

          <ItemForm visible={readOnly}>
        <div style={{display:"flex",justifyContent:"flex-end"}}>
          <Button text={t("edit")} icon='edit' stylingMode='contained' type='default' onClick={()=>{setReadOnly(false)}} />
        <div style={{ marginLeft: '10px' }}>
          <Button text={t("close")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={closePopup} />
        </div>
        </div>
          </ItemForm>


        <ColCountByScreen xs={1} />


        <GroupItem>
          <ItemForm>
          <CheckBox
              value={user.userActive==1}
              text={t("active")}
              onValueChanged={(e) => updateFieldd('userActive')(e.value?1:0)}
              readOnly={readOnly}
            />
          </ItemForm>

          <ItemForm>
            <TextBox
              label={t("userName")}
              value={user.userName}
              readOnly={readOnly}
              onValueChange={updateFieldd('userName')}

            />
          </ItemForm>

          <ItemForm>
            <TextBox
              label={t("email")}
              value={user.userEmail}
              readOnly={readOnly}
              inputAttr={{ autocomplete: 'off' }}
              onValueChange={updateFieldd('userEmail')}

            />
          </ItemForm>


          <ItemForm editorOptions={passwordEditorOptions} >
                    <div style={{ position: 'relative' }}>
                      <TextBox
                        label={t("password")}
                        value={user.userPassword}
                        mode={isPasswordVisible ? 'text' : 'password'}
                        inputAttr={{ autocomplete: 'new-password' }}
                        onValueChange={updateFieldd('userPassword')}
                        readOnly={readOnly}
                      />
                       <div
                        onClick={togglePasswordVisibility}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer'
                        }}
                      >
                        {isPasswordVisible ? <EyeOff /> : <Eye />}
                      </div>
                    </div>
          </ItemForm>

          <ItemForm>
            <TextBox
              label={t("comment")}
              value={user.comment}
              readOnly={readOnly}
              onValueChange={updateFieldd('comment')}
            />
          </ItemForm>

        </GroupItem>

      </GroupItem>

    </From>
  );
};
