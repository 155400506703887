import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DataGrid, Column, Selection, Editing, Scrolling, SearchPanel, ColumnChooser, Export, HeaderFilter, Sorting, Toolbar, Item, DataGridTypes, Lookup, FilterPanel, FilterBuilderPopup, DataGridRef } from 'devextreme-react/data-grid';
import { campaignCustomerDeleteRequest, campaignCustomerShippingAdressListRequest, campaignCustomersImportRequest, campaignCustomerUpdateRequest, generalCampaignCustomerListRequest } from '../../../api/requests';
import { Button, DropDownButton, LoadPanel } from 'devextreme-react';
import CustomerSearch from './searchCustomer';
import notify from 'devextreme/ui/notify';
import { csv_to_json } from '../../../utils/cvs_to_json-converter';
import { useTranslation } from 'react-i18next';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { appInfo } from '../../../app-info';
import { confirm } from 'devextreme/ui/dialog';

const CampaignCustomerList = ({ campaignRef,readOnly, expandTable, setExpandTable, isPopupOpened }) => {
    const [customers, setCustomers] = useState([]);
    const {t} = useTranslation();
    const gridRef = useRef<DataGridRef>(null);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        refresh();
    }, [campaignRef]);

    function refresh(){
        if(campaignRef != 0){
            gridRef.current!.instance().beginCustomLoading(t("loading"));
            generalCampaignCustomerListRequest(campaignRef)
            .then((response) => {
                setCustomers(response.dataSet || []);
            })
            .catch((error) => {
                console.error('Error fetching product list:', error);
            }).finally(()=>{
                gridRef.current!.instance().endCustomLoading();
            });
        }
    }


    const [isPanelOpened, setPanelOpened] = useState(false);


    const updateShippingAdressList = (erpCustomerRef) => {
        campaignCustomerShippingAdressListRequest(erpCustomerRef).then((response) => {
            if (response.resCode == 200) {
               // setShippingAddressList(response.dataSet); // Gelen veriyi set et
            } else {
                notify(response.resMessage, "error");
            }
        });
    };

    const customerSearchPanelOpen = useCallback(() => {
        if(!readOnly){
            notify(t("saveAboveInformation"),"warning");
        }else{
            setPanelOpened(!isPanelOpened);
        }
    }, [isPanelOpened,readOnly]);

    const onRowUpdating = (e) => {
        const updatedData = { ...e.oldData, ...e.newData };
        console.log(updatedData)

        campaignCustomerUpdateRequest(campaignRef, updatedData, e.oldData["erpShippingAdrRef"],e.newData["erpShippingAdrRef"]).then((response) => {
            if (response.resCode === 200) {
                notify(t("updateSuccessfull"), "success", 1000);
            } else {
                notify(response["resMessage"], "error", 2500);
                refresh();
            }
        });

    };

    const onRowRemoving = (e) => {
        const customer = e.data;

        return campaignCustomerDeleteRequest(campaignRef,customer)
            .then((response) => {
                if (response.resCode === 200) {
                    notify(t("successfull"), "success", 1000);
                } else {
                    notify(response.resMessage, "error", 2500);
                    refresh();
                    throw new Error(response.resMessage);
                }
            })
            .catch((error) => {
                console.error('Error deleting product:', error);
            });

    };

    const customers_import = () => {
        csv_to_json().then((json)=>{
         var list = JSON.parse(json);
         campaignCustomersImportRequest(campaignRef,list).then((response)=>{
             if(response.resCode == 200){
                 notify(t("successfull"));
             }else{
                 notify(response.resMessage,"error")
             }
             refresh();
         })
        });
     };

     const onExporting = (e: DataGridTypes.ExportingEvent) => {
        if (e.format === 'pdf') {
          const doc = new JsPdf();
          exportDataGridToPdf({
            jsPDFDocument: doc,
            component: e.component,
          }).then(() => {
            doc.save('Products.pdf');
          });
        } else {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Page1');

          exportDataGridToXLSX({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CampaignCustomers.xlsx');
            });
          });
          e.cancel = true;
        }
      };

      const onSelectionChanged = (e) => {
        setSelectedRows(e.selectedRowsData);
    };

      async function deleteSelected(){
        const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
          if (result) {
            gridRef.current?.instance().beginCustomLoading(t("loading"));
            var i = 0;
            selectedRows.forEach((row, index) => {

                campaignCustomerDeleteRequest(campaignRef,row).then((response)=>{
                    if(response["resCode"]==200){
                    }else{
                        notify(response["resMessage"],"error");
                    }
                    i++;
                    if(i==selectedRows.length){
                        gridRef.current?.instance().endCustomLoading();
                        refresh();
                    }
                });
            });
          }
      }


    function expandTableFunc(){
        if(expandTable){
          setExpandTable(false)
        }else{
          setExpandTable(true)
        }
      }

    return (
        <>
        <div style={{height:"100%"}}>
        <div style={{flex:1, overflowY:"visible"}}>
            <DataGrid
            className='custom-grid'
            dataSource={customers}
            ref={gridRef}
            onRowUpdating={onRowUpdating}
            onRowRemoving={onRowRemoving}
            onExporting={onExporting}
            onSelectionChanged={onSelectionChanged}
            rowAlternationEnabled
            keyExpr="campaignCustomerRef"
            noDataText={t("noData")}
            height={expandTable?"calc(95vh - 120px)":"calc(95vh - 351px)"}
            editing={{
                mode: 'row',
                allowUpdating: true,
                allowDeleting: true,
                allowAdding: false,
            }}

        >
        <Toolbar>
                    <Item name='searchPanel' location='before'  />

                    <Item location='before' locateInMenu='auto'>
                                <Button
                                        icon='refresh'
                                        stylingMode='text'
                                        hint={t("refresh")}
                                        onClick={refresh}
                                    />
                    </Item>

                    <Item name='columnChooserButton' location='before' />

                    <Item name='exportButton' location='before' />

                    <Item location="before">
                        <DropDownButton
                        visible={selectedRows.length>0}
                            text={t("transactions")}
                            icon="menu"
                            width={"150"}
                            items={[
                            { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                            ]}
                            onItemClick={(e) => {
                            if(e.itemData.action=="deleteSelected"){
                                deleteSelected();
                            }
                            }}
                        />
                        </Item>

                    <Item location='after' locateInMenu='auto'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                    <Button
                        icon="plus"
                        type="default"
                        stylingMode="contained"
                        hint={t("selectCustomers")}
                        onClick={customerSearchPanelOpen}
                        disabled={!readOnly}

                    />

                    <div style={{ marginLeft: '10px' }}>
                    <Button
                        icon="import"
                        type="default"
                        stylingMode="contained"
                        hint={t("importFolder")}
                        onClick={customers_import}
                        disabled={!readOnly}
                    />
                        </div>

                        <div style={{ marginLeft: '10px' }}>
                        <Button
                                icon={expandTable?"chevrondown":"fullscreen"}
                                type="default"
                                hint={t("expandTable")}
                                stylingMode="contained"
                                onClick={expandTableFunc}
                                disabled={!readOnly}
                            />
                           </div>

                    </div>
        </Item>

        </Toolbar>
        <LoadPanel showPane={false}  />
        <SearchPanel visible placeholder={t("search")} width={"300px"} />
        <ColumnChooser  enabled  title={t("columnChooser")}/>
        <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
        <HeaderFilter visible />
        <Sorting mode='multiple' />
        <Scrolling mode='virtual' />
        <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
        <FilterPanel visible={true} />
        <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />
        <Editing
        texts={{
            editRow: t("edit"),
            deleteRow: t("delete"),
            confirmDeleteMessage: t("confirmDeleteMessage"),
            confirmDeleteYes: t("yes"),
            cancelRowChanges: t("cancel"),
            saveRowChanges: t("save")
        }}/>

        <Column dataField="customerCode" caption={t("customerCode")} width={150} allowEditing={false} />
        <Column dataField="customerName" caption={t("customerName")} allowEditing={false} />
        <Column dataField="invoiceAdr" caption={t("address")} allowEditing={false} />
        <Column dataField="customerAdr" caption={t("invoiceAddress")} visible={false} allowEditing={false} />
        <Column dataField="shippingAdr" caption={t("shippingAdr")} visible={false} allowEditing={false} />
        <Column dataField="comment" caption={t("comment")} />

        <Column dataField="shippingCountry" caption={t("country")} visible={false} />
        <Column dataField="speCode1" caption={t("specCode1")} visible={false} />
        <Column dataField="speCode2" caption={t("specCode2")} visible={false} />
        <Column dataField="speCode3" caption={t("specCode3")} visible={false} />
        <Column dataField="speCode4" caption={t("specCode4")} visible={false} />
        <Column dataField="speCode5" caption={t("specCode5")} visible={false} />

    </DataGrid>

        </div>

        </div>
    <CustomerSearch customersOnCampaign={customers} visible={isPanelOpened} onHiding={customerSearchPanelOpen} campaignRef={campaignRef} refresh={refresh}></CustomerSearch>
    </>
    );
};

export default CampaignCustomerList;
