import React, { useState } from 'react';
import Bullet, { Size, Tooltip } from 'devextreme-react/bullet';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box'; // DevExtreme'den SelectBox bileşeni
import { CardAnalytics } from '../../library/card-analytics/CardAnalytics';
import { SaleByStateAndCity } from '../../../types/analytics';

import './RevenueAnalysisByStatesCard.scss';

export const RevenueAnalysisByStatesCard = ({ datasource }) => {
  const [selectedRouteStatus, setSelectedRouteStatus] = useState(2); // Varsayılan değer 2

  const routeStatusOptions = [
    { value: 0, label: 'Bekleyen' },
    { value: 1, label: 'Devam Eden' },
    { value: 2, label: 'Tamamlanan' },
  ];

  const handleRouteStatusChange = (e) => {
    setSelectedRouteStatus(e.value);
  };

  return (
    <CardAnalytics
      title='Visits by Sales Person'
      contentClass='sales-by-state-and-city grid'
      additionalHeaderContent={
        <><SelectBox
        width={150}
          items={routeStatusOptions}
          displayExpr='label'
          valueExpr='value'
          value={selectedRouteStatus}
          onValueChanged={handleRouteStatusChange}
        /></>
      }
    >
      <DataGrid dataSource={datasource.filter(item => item.routeStatus == selectedRouteStatus)} height={290}>
        <Column caption='Route Code' width={110} dataField='routeCode' />
        <Column caption='Sales Person' width={150} dataField='salesperson' />
        <Column caption='Customer Name' dataField='customerName' />
        <Column caption='Duration' width={120} dataField='durationMinutes' alignment='center' />
      </DataGrid>
    </CardAnalytics>
  );
};
