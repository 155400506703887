import React, { useState, useCallback, useEffect } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import classNames from 'classnames';
import { Button, ButtonTypes } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import Form, { Item as FormItem, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { CampaignModel } from '../../../types/crm-contact';
import ValidationGroup from 'devextreme-react/validation-group';
import notify from 'devextreme/ui/notify';
import { generalCampaignPostRequest } from '../../../api/requests';
import { useTranslation } from 'react-i18next';
import { DateBox, SelectBox, TextBox } from 'devextreme-react';
import { customerSelectionTypes } from '../../../shared/select-boxes';
import { newCampaignModel } from '../../../shared/constants';

export const CampaignInfoUpdate = (
  {changePanelOpened, mainRefresh, campaign: initialCampaign , setCampaignProductListRefresh,readOnlyTrue, readOnlyFalse, readOnly,isNewRecord,openNewRow,isPanelOpened } :
  {changePanelOpened, mainRefresh: () => void, campaign: CampaignModel, setCampaignProductListRefresh,readOnlyTrue,readOnlyFalse,readOnly,isNewRecord,openNewRow,isPanelOpened }
) => {

  const [campaign, setCampaign] = useState(initialCampaign);

  useEffect(() => {
    if(isPanelOpened){
      if(isNewRecord){
        setCampaign(newCampaignModel);
        const today = new Date();
        const week = new Date(today);
        week.setDate(today.getDate() + 7);
        const formatDate = (date) => {
          return date.toISOString().split('T')[0];
        };
        const todayDate = formatDate(today);
        const weekDate = formatDate(week);

        updateField("beginDate")(todayDate);
        updateField("endDate")(weekDate);
      }
    }
  }, [isPanelOpened]);


  function onCancelClick(){
    if(isNewRecord){
      changePanelOpened();
    }else{
      readOnlyTrue();
    }
  }

  function onSaveClick () {

    generalCampaignPostRequest(campaign).then((res) => {
      if (res["resCode"] == 200) {
        if(isNewRecord){
          changePanelOpened();
          openNewRow(res.dataSet[0]["campaignRef"]);
        }else{
          mainRefresh();
          setCampaignProductListRefresh(prev => prev + 1);
          notify(t("updateSuccessfull"), "success");
        }
      } else {
        notify(res["resMessage"], "error");
      }
    }).finally(()=>readOnlyTrue());
  }

  const { t } = useTranslation();

  const updateField = (key) => (value) => {
    setCampaign(prev => ({
      ...prev,
      [key]: value
    }));
  };


  return (
    <>
      <ScrollView className='panel-scroll'>
        <ValidationGroup>
          <div className='data-part'>
            <Form
              className={classNames({ 'plain-styled-form': true, 'view-mode': readOnly })}
            >
              <GroupItem colCount={2}>
              <ToolbarItem location='before' cssClass="left-aligned">

                <CheckBox
                      value={campaign.campaignStatus == 1}
                      text={t("active")}
                      onValueChanged={(e) => {
                        updateField('campaignStatus')(e.value ? 1 : 0);
                      }}
                      readOnly={readOnly}
                      />

                </ToolbarItem>


                <ToolbarItem location='after' visible={!readOnly} cssClass="right-aligned">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                        text={t("save")}
                        icon='save'
                        stylingMode='contained'
                        type='default'
                        onClick={onSaveClick}
                      />

                  </div>
                </ToolbarItem>

              </GroupItem>

              <GroupItem colCount={5} cssClass='photo-row'>
                <ColCountByScreen xs={2} />

                <GroupItem colCount={1}>
                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("campaignCode")}
                      value={campaign.campaignCode}
                      readOnly={true}
                      onValueChange={updateField('campaignCode')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("campaignName")}
                      value={campaign.campaignName}
                      readOnly={readOnly}
                      onValueChange={updateField('campaignName')}
                    />
                  </FormItem>
                </GroupItem>

                <GroupItem colCount={1}>
                  <FormItem cssClass=''>
                    <DateBox
                      label={t("beginDate")}
                      value={campaign.beginDate}
                      onValueChanged={(e) => updateField('beginDate')(e.value)}
                      displayFormat="dd-MM-yyyy"
                      readOnly={readOnly}
                    />
                  </FormItem>

                  <FormItem cssClass=''>
                    <DateBox
                      label={t("endDate")}
                      value={campaign.endDate}
                      onValueChanged={(e) => updateField('endDate')(e.value)}
                      displayFormat="dd-MM-yyyy"
                      readOnly={readOnly}
                    />
                  </FormItem>
                </GroupItem>

                <GroupItem colCount={1}>
                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("specCode1")}
                      value={campaign.erpClSpeCode1}
                      readOnly={readOnly}
                      onValueChange={updateField('erpClSpeCode1')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("specCode2")}
                      value={campaign.erpClSpeCode2}
                      readOnly={readOnly}
                      onValueChange={updateField('erpClSpeCode2')}
                    />
                  </FormItem>
                </GroupItem>

                <GroupItem colCount={1}>
                  <FormItem cssClass='accent'>
                    <TextBox
                      label={t("erpCostCode")}
                      value={campaign.erpCostCode}
                      readOnly={readOnly}
                      onValueChange={updateField('erpCostCode')}
                    />
                  </FormItem>

                  <FormItem cssClass='accent select-box-always-bordered'>
                    <SelectBox
                    value={campaign.customerSelectionType}
                    dataSource={customerSelectionTypes(t)}
                    onValueChange={updateField('customerSelectionType')}
                    label={t("customerSelectionType")}
                    displayExpr="title"
                     valueExpr="customerSelectionType" ></SelectBox>
                  </FormItem>

                  <FormItem visible={false} cssClass='accent'>
                    <TextBox
                      label={t("comment")}
                      value={campaign.comment}
                      readOnly={readOnly}
                      onValueChange={updateField('comment')}
                    />
                  </FormItem>
                </GroupItem>

                <GroupItem colCount={1}>
                  <FormItem cssClass='accent'>
                    <CheckBox
                      value={campaign.runManually == "1"}
                      text={t("runManually")}
                      onValueChanged={(e) => {
                        updateField('runManually')(e.value ? "1" : "0");
                      }}
                      readOnly={readOnly}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <CheckBox
                      value={campaign.amountDepended == "1"}
                      text={t("amountDepended")}
                      onValueChanged={(e) => {
                        updateField('amountDepended')(e.value ? "1" : "0");
                      }}
                      readOnly={readOnly}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <CheckBox
                      value={campaign.isDiscPercent == "1"}
                      text={t("isDiscPercent")}
                      onValueChanged={(e) => {
                        updateField('isDiscPercent')(e.value ? "1" : "0");
                      }}
                      readOnly={readOnly}
                    />
                  </FormItem>

                  <FormItem cssClass='accent'>
                    <CheckBox
                      value={campaign.forCertainCustomers == "1"}
                      text={t("forCertainCustomers")}
                      onValueChanged={(e) => {
                        updateField('forCertainCustomers')(e.value ? "1" : "0");
                      }}
                      readOnly={readOnly}
                    />
                  </FormItem>
                </GroupItem>
              </GroupItem>
            </Form>
          </div>

          <div style={{padding: "5px"}}> </div>
        </ValidationGroup>
      </ScrollView>
    </>
  );
};
