import {  Button, Popup } from "devextreme-react";
import { CustomerModel } from "../../../types/crm-contact";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { B2BCustomerDetails } from "../../../pages";
import { B2BUsersDetails } from "../../../pages/b2b-users-details/b2b-users-details";

export const B2BUsersPopup = (
    { mainRefresh, rowData , isPanelOpened,changePanelOpened,isNewRecord,closePopup } :
    { mainRefresh: () => void, rowData, isPanelOpened,changePanelOpened,isNewRecord,closePopup }
  ) =>{
    const [readOnly, setReadOnly] = useState(true);

    useEffect(()=>{
        if(isPanelOpened){
            setExpandTable(false)
            if(isNewRecord){
                setReadOnly(false)
            }else{
                setReadOnly(true)
            }
        }
    },[isPanelOpened]);

    const readOnlyTrue = useCallback(() => {
        setReadOnly(true);
    }, [readOnly]);

    const readOnlyFalse = useCallback(() => {
        setReadOnly(false);
    }, [readOnly]);

    const {t} = useTranslation();

    const[expandTable, setExpandTable] = useState(false);

    return (
        <Popup
                    key={rowData?.userRef}
                    visible={isPanelOpened}
                    hideOnOutsideClick={true}
                    title={rowData?.userName}
                    showCloseButton={true}
                    width={"90%"}
                    height={"90%"}
                    onHiding={changePanelOpened}
                    titleRender={()=>(
                        <div className="flex flex-row justify-between">
                          <div className="flex flex-row">
                          <Button icon="close" stylingMode="text" onClick={changePanelOpened}></Button>
                          <p className="ml-2 mt-0 justify-center font-bold text-lg">{`${rowData?.userName}`}</p>
                          </div>

                        </div>
                      )}
                    contentRender={() => (


                <B2BUsersDetails mainRefresh={mainRefresh} closePopup={closePopup} rowData={rowData}></B2BUsersDetails>

                    )}
                        />);

}