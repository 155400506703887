import { Button, Popup, SelectBox } from "devextreme-react";
import { useTranslation } from "react-i18next";
import { RegistrationStatus } from "../../utils/contact-status/ContactStatus";
import { useState } from "react";

export const RegistrantPopup = ({ registrant, visible, onHiding }) => {
    const {t} = useTranslation();

    const [selectedStatus, setSelectedStatus] = useState<number>(0);

  const statusOptions= [
    { value: 0, label: t("requested"), color:'blue' },
    { value: 1, label: t("approved"), color: 'green' },
    { value: 2, label: t("rejected"), color: 'red' },
  ];

  const handleChange = (e: any) => {
    setSelectedStatus(e.value);
  //  registrant.requestStatus = e.value;
  };

  const itemTemplate = (data) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: data.color,
            marginRight: '8px',
          }}
        ></span>
        {data.label}
      </div>
    );
  };

    return (
        <Popup
            visible={visible}
            onHiding={onHiding}
            title={`${registrant?.shopUserEmail || 'Kayıt Bilgisi'}`}
            height="auto"
            maxHeight={"95%"}
            minWidth={"50%"}
            maxWidth={1000}
            hideOnOutsideClick
            titleRender={()=>(
              <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                <Button icon="close" stylingMode="text" onClick={onHiding}></Button>
                <p className="ml-2 mt-0 justify-center font-bold text-lg">{`${registrant?.shopUserEmail || 'Kayıt Bilgisi'}`}</p>
                </div>
                <div className="flex flex-row gap-x-1">
                        <Button
                            text={t("save")}
                            icon='save'
                            stylingMode='contained'
                            type='default'
                         />

                        <div className="hidden sm:block">
                        <Button
                              text={t("cancel")}
                              icon='close'
                              stylingMode='contained'
                              style={{backgroundColor:"gray"}}
                              type='default'
                              onClick={onHiding}
                            />
                        </div>
                  </div>
              </div>
            )}
            contentRender={() => (
                <div>
                    <div className="flex flex-row justify-between">
                        <div className="">
                            <SelectBox
                                dataSource={statusOptions}
                                value={selectedStatus}
                                defaultValue={0}
                                displayExpr="label"
                                valueExpr="value"
                                onValueChanged={handleChange}
                                itemRender={itemTemplate}
                                searchEnabled={false}
                            ></SelectBox>
                        </div>

                    </div>


                    <div className="flex flex-col md:flex-row gap-2">

                    <div className="p-5 my-2 w-full md:w-1/2  rounded-md shadow-md border border-gray-300">
                  <p className="font-bold py-1 ">{t("firmInfo")}</p>
                  <hr className="p-1"></hr>
                    <div className="py-1">
                      <label className="mt-2 ">{t("firmName")}</label>
                      <input disabled value={registrant?.firmName} name="customerName" type="text" className=" p-1 border bg-transparent border-gray-300 rounded w-full focus:ring-0"  />
                    </div>

                    <div className="grid grid-cols-2 gap-2 py-1">
                    <div>
                      <label className="mt-2">{t("taxID")}</label>
                      <input disabled value={registrant?.firmTaxNr} name="customerVATNr" type="text" className="w-full p-1 border bg-transparent border-gray-300 rounded focus:ring-0"/>
                    </div>
                    <div>
                      <label className="mt-2">{t("taxNumber")}</label>
                      <input disabled value={registrant?.firmVATNr} name="customerTaxNr" type="text" className="w-full p-1 border bg-transparent border-gray-300 rounded focus:ring-0"/>
                    </div>
                    </div>
                  </div>

                  <div className="p-5 my-2 w-full md:w-1/2 rounded-md gap-2 shadow-md border border-gray-300">
                  <p className="font-bold py-1">{t("userInfo")}</p>
                  <hr className="p-1"></hr>

                      <div className="grid grid-cols-2 gap-2 py-1">

                      <div>
                      <label className="mt-2">{t("userName")}</label>
                      <input disabled value={registrant?.shopUserName} name="customerAdrInfo" type="text" className="w-full p-1 border bg-transparent border-gray-300 rounded focus:ring-0" />
                      </div>

                      <div>
                        <label className="mt-2">{t("email")}</label>
                        <input disabled value={registrant?.shopUserEmail} name="customerAdrInfo" type="text" className="w-full p-1 border bg-transparent border-gray-300 rounded focus:ring-0" />
                      </div>

                      </div>

                      <div className="grid grid-cols-2 gap-2 py-1">

                      <div>
                      <label className="mt-2">{t("phone")}</label>
                      <input disabled value={registrant?.shopUserPhone} name="customerAdrInfo" type="text" className="w-full p-1 border bg-transparent border-gray-300 rounded focus:ring-0" />
                      </div>



                      </div>
                </div>


                </div>


                <div className="flex flex-col md:flex-row gap-2">

                <div className="p-5 my-2 w-full md:w-1/2 rounded-md gap-2 shadow-md border border-gray-300">
                  <p className="font-bold py-1">{t("firmAddress")}</p>
                  <hr className="p-1"></hr>
                      <div className="gap-2">
                      <label className="mt-2">{t("address")}</label>
                      <input disabled value={registrant?.firmAddress} name="customerAddress" type="text" className="w-full p-1 border bg-transparent border-gray-300 rounded focus:ring-0" required/>
                      </div>

                      <div className="grid grid-cols-2 gap-2 py-1">

                      <div>
                      <label className="mt-2">{t("country")}</label>
                      <input disabled value={registrant?.firmCountry} name="customerAdrInfo" type="text" className="w-full p-1 border bg-transparent border-gray-300 rounded focus:ring-0" />

                      </div>

                      <div>
                        <label className="mt-2">{t("moreInfo")}</label>
                        <input disabled value={registrant?.firmAdrInfo} name="customerAdrInfo" type="text" className="w-full p-1 border bg-transparent border-gray-300 rounded focus:ring-0" />
                      </div>

                      </div>
                </div>

                <div className="p-5 my-2 w-full md:w-1/2 rounded-md gap-2 shadow-md border border-gray-300">
                  <p className="font-bold py-1">{t("shippingAddress")}</p>
                  <hr className="p-1"></hr>
                      <div className="gap-2">
                      <label className="mt-2">{t("address")}</label>
                      <input disabled value={registrant?.shippingAdr} name="customerAddress" type="text" className="w-full p-1 border bg-transparent border-gray-300 rounded focus:ring-0" required/>
                      </div>

                      <div className="grid grid-cols-2 gap-2 py-1">

                      <div>
                      <label className="mt-2">{t("country")}</label>
                      <input disabled value={registrant?.shippingCountry} name="customerAdrInfo" type="text" className="w-full p-1 border bg-transparent border-gray-300 rounded focus:ring-0" />

                      </div>

                      <div>
                        <label className="mt-2">{t("moreInfo")}</label>
                        <input disabled value={registrant?.shippingAdrInfo} name="customerAdrInfo" type="text" className="w-full p-1 border bg-transparent border-gray-300 rounded focus:ring-0" />
                      </div>

                      </div>
                </div>

                </div>

                <div className="p-5 my-2 w-full rounded-md gap-2 shadow-md border border-gray-300">
                  <p className="font-bold py-1">{t("comment")}</p>
                  <hr className="p-1"></hr>
                      <div className="gap-2">
                      <textarea disabled value={registrant?.requestNote} name="customerAddress" rows={2} className="w-full p-1 border bg-transparent border-gray-300 rounded focus:ring-0" required/>
                      </div>
                </div>



                </div>
            )}
        />
    );
};
