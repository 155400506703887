import { Task } from '../types/task';
import { CampaignModel, CustomerGroupModel, CustomerModel, CustomerUserModel, RouteLine as RouteLineModel, RouteModel, SalesManModel, SegmentCustomerModel, SegmentModel, SegmentProductGroupModel, SegmentProductModel } from '../types/crm-contact';
import { DateTime } from 'rrule/dist/esm/datetime';

export const PRIORITY_ITEMS = ['Low', 'Normal', 'High'];
export const STATUS_ITEMS = ['Open', 'In Progress', 'Deferred', 'Completed'];

export const ANALYTICS_PERIODS = {
  "Day": {
    period: calculatePeriod(1),
    index: 0,
  },
  "Week": {
    period: calculatePeriod(7),
    index: 1,
  },
  "Month": {
    period: calculatePeriod(30),
    index: 2,
  },
  "Year": {
    period: calculatePeriod(365),
    index: 3,
  }
};

export function calculatePeriod(daysAgo: number): string {
  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setDate(startDate.getDate() - daysAgo);

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  return `${formattedStartDate}/${formattedEndDate}`;
}

function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}


export const DEFAULT_ANALYTICS_PERIOD_KEY = 'Week';

export const CONTACT_STATUS_LIST = [
  'active',
  'passive',
];

export const CONTACT_STATUS_LIST2 = [
  1,
  0,
];

export const newTask: Task = {
  id: 0,
  text: '',
  description: '',
  company: '',
  priority: 'Low',
  startDate: new Date(),
  dueDate: new Date(),
  owner: '',
  status: 'Open',
  activities: [],
  notes: [],
  messages: [],
  parentId: 0,
  progress: 0,
};

export interface SalesDatum {
  currRevTotal: number;
  currentMonth: number;
  currentWeek: number;
  currentYear: number;
  date: DateTime;
  category: String;
}

export const newCustomerUserModel: CustomerUserModel = {
  customerUserRef: 0,
  customerRef: 0,
  userRef: 0,
  userActive: 1,
  userName: "",
  userEmail: "",
  userPassword: "",
  comment: ""
}

export const newCustomerGroupModel: CustomerGroupModel = {
    customerActive: 1,
    customerGroupCode: "",
    customerGroupName: "",
    customerGroupRef: 0,
    erpBranch: 0,
    erpFirmNr: 0,
    erpWarehouse: 0,
    erpSalesManCode: "",
    productGroupRef: 0,
    comment: ""
}

export const newCampaignModel: CampaignModel = {
  campaignRef: 0,
  campaignStatus: 1,
  runManually: "0",
  amountDepended: "0",
  forCertainCustomers: "0",
  customerSelectionType: 0,
  isDiscPercent: "0",
  campaignCode: "",
  campaignName: "",
  erpClSpeCode1: "",
  erpClSpeCode2: "",
  erpCostCode: "",
  beginDate: "",
  endDate: "",
  comment: "",
  delete:0,
}


export const newContact: CustomerModel = {
id: 0,
comment:"",
customerActive:0,
customerCode:"",
customerGroupCode:"",
customerGroupName:"",
customerGroupRef:0,
customerName:"",
customerRef:0,
erpCustomerAddr1:"",
erpCustomerCity:"",
erpCustomerCode:"",
erpCustomerName1:"",
erpCustomerPostCode:"",
erpCustomerRef:0,
erpShippingAdrAddr1:"",
erpShippingAdrCity:"",
erpShippingAdrCode:"",
erpShippingAdrName1:"",
erpShippingAdrPostCode:"",
erpShippingAdrRef:0,
productGroupCode:"",
productGroupName:"",
productGroupRef:0
};


export const newSalesManModel: SalesManModel = {
  salesManRef: 0,
  salesManActive: 1,
  salesManName: "",
  salesManEMail: "",
  salesManPassword: "",
  erpSlsManRef: 0,
  erpSlsManCode: "",
  erpSlsManName1: "",
  erpFirmNr: "",
  erpBranch: "",
  erpWHCentral: "",
  erpWHReturn: "",
  erpWHScrap: "",
  erpCashBoxCode: "",
  erpDiscLimit: "",
  erpSalesOrderStatus: 0,
  rightSalesOrder: 0,
  rightSalesDispatch: 0,
  rightSalesDispatchRt: 0,
  rightSalesInvoice: 0,
  rightSalesInvoiceRt: 0,
  rightPaymentCash: 0,
  rightGetPdf: 0,
  rightGetAllProducts: 0,
  routeActive: 0,
  routeChangeDataAfterSave: 0,
  routeSendDataAfterSave: 0,
  comment: "",
  createDate: ""
};

export const newRouteModel: RouteModel = {
  routeRef: 0,
  routeActive: 1,
  routeStatus: 1,
  routeCode: "",
  routeName: "",
  routePlanRef: 0,
  erpSlsManRef: 0,
  erpSlsManCode: "",
  erpVehicleRef: 0,
  routeDate: new Date().toISOString(),
  beginTime: "",
  endTime: "",
  comment: ""
};

  export const newRouteLine: RouteLineModel = {
    routeLineRef: 0,
    routeLineActive: 1,
    routeRef: 0,
    routeLineType: 0,
    routeLineNr: 1,
    erpCustomerRef: 0,
    erpCustomerCode: "",
    erpCustomerName: "",
    erpCustomerAdr: "",
    erpShipInfoRef: 0,
    erpShippingAdr: "",
    erpShippingAdrRef: 0,
    formLineRef: 0,
    routeLineStatus: 1,
    beginDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    comment: ""
  };

  export const newSegmentModel: SegmentModel = {
    segmentRef: 0,
    segmentType: 0,
    segmentStatus: 1,
    segmentCode: "",
    segmentName: "",
    speCode1: "",
    speCode2: "",
    productSelectionType: 0,
    customerSelectionType: 0,
    comment: ""
  };

  export const newSegmentProductModel: SegmentProductModel = {
    segmentProductRef: 0,
    segmentProductType: 0,
    segmentRef: 0,
    erpItemRef: 0,
    itemCode: "",
    itemName: "",
    customerRef: 0,
    erpCustomerRef: 0,
    customerCode: "",
    customerName: "",
    customerAdr: "",
    erpShippingAdrRef: 0,
    shippingName: "",
    shippingAdr: "",
    comment: ""
  };

  export const newSegmentCustomerModel: SegmentCustomerModel = {
    segmentCustomerRef: 0,
    segmentCustomerType: 0,
    segmentRef: 0,
    customerRef: 0,
    erpCustomerRef: 0,
    customerCode: "",
    customerName: "",
    customerAdr: "",
    erpShippingAdrRef: 0,
    shippingName: "",
    shippingAdr: "",
    comment: ""
  };


  export const newSegmentProductGroupModel: SegmentProductGroupModel = {
    segmentProductGroupRef: 0,
    segmentProductGroupType: 0,
    segmentRef: 0,
    productGroupCode: "",
    comment: ""
  };