import React, { useState, useEffect, useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Column, Toolbar, Item, LoadPanel,
  Export,
  DataGridTypes,
  FilterPanel,
  FilterBuilderPopup,
  DataGridRef,

} from 'devextreme-react/data-grid';

import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';

import {  generalSegmentationBrandsInsertRequest, segmentBrandsSearchListRequest,} from '../../../api/requests';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { appInfo } from '../../../app-info';

const BrandsSearch = ({ visible, onHiding, segmentRef, getList }) => {
    const [brands, setProductGroups] = useState([]);
    const {t} = useTranslation();
    const [selectedProductGroups, setSelectedProductGroups] = useState([]); // Seçili ürünlerin yönetimi için
    const gridRef = useRef<DataGridRef>(null);
    const [totalCount, setTotalCount]= useState(0);

    useEffect(() => {
        if (visible) {
          getListFromApi();
        }
        clearSelections();
      }, [visible]);

  function getListFromApi(){
    gridRef.current?.instance().beginCustomLoading(t("loading"))
    segmentBrandsSearchListRequest(segmentRef).then(data => {
      if(data.resCode==200){
        setProductGroups(data.itemSubCategory);
        setTotalCount(data.itemSubCategory.length);
      }else{
        notify(data.resMessage,"error");
      }
  }).finally(()=>{
    gridRef.current?.instance().endCustomLoading()
  });
  }


    const onAddButtonClick = () => {
        gridRef.current?.instance().beginCustomLoading(t("loading"));
        var i =0;
        selectedProductGroups.forEach((brand, index) => {

            generalSegmentationBrandsInsertRequest(segmentRef,brand).then((response)=>{
                if(response["resCode"]==200){
                    notify(brand["itemSubCategory"]+" eklendi","success");

                }else{
                    notify(response["resMessage"],"error");
                }
                i++;
                if(i==selectedProductGroups.length){
                  gridRef.current?.instance().endCustomLoading();
                    onHiding();
                    getList();
                    clearSelections();
                }
            });
        });


    };

    const clearSelections = () => {
        setSelectedProductGroups([])
        gridRef.current?.instance().clearFilter();
        gridRef.current?.instance().clearSelection();
    };


    const onSelectionChanged = (e) => {
        setSelectedProductGroups(e.selectedRowsData); // Seçili satırları güncelle
    };

    const onExporting = (e: DataGridTypes.ExportingEvent) => {
        if (e.format === 'pdf') {
          const doc = new JsPdf();
          exportDataGridToPdf({
            jsPDFDocument: doc,
            component: e.component,
          }).then(() => {
            doc.save('Products.pdf');
          });
        } else {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet('Page1');

          exportDataGridToXLSX({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Products.xlsx');
            });
          });
          e.cancel = true;
        }
      };

    return (
        <Popup
          visible={visible}
          onHiding={onHiding}
          title={t("brands")}
          width={"80%"}
          height={"80%"}
          dragEnabled={true}
          titleRender={()=>(
            <div className="flex flex-row justify-between">
              <div className="flex flex-row">
              <Button icon="close" stylingMode="text" onClick={onHiding}></Button>
              <p className="ml-2 mt-0 justify-center font-bold text-lg">{t("brands")}</p>
              </div>
              <div className="flex flex-row gap-x-1">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      icon='check'
                      text={t("select")}
                      type='default'
                      stylingMode='contained'
                      visible={true}
                      onClick={onAddButtonClick}
                      disabled={!(selectedProductGroups.length>0)}
                    />
                    <div style={{ marginLeft: '10px' }}>
                      <Button
                      text={t("close")}
                      icon='close'
                      stylingMode='contained'
                      style={{backgroundColor:"gray"}}
                      type='default'
                      onClick={onHiding}
                    />
                    </div>
                  </div>
                </div>
            </div>
          )}
          showCloseButton={true}

        >

        <DataGrid
          ref={gridRef}
          dataSource={brands}
          keyExpr="itemSubCategory"
          onSelectionChanged={onSelectionChanged}
          showBorders={true}
          onExporting={onExporting}
          noDataText={t("noData")}
          columnAutoWidth={false}
          allowColumnReordering
          height={"100%"}
          >


          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <LoadPanel showPane={false}  />
          <SearchPanel placeholder={t("search")} searchVisibleColumnsOnly={false} width={"300px"} visible={true} />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Selection  mode='multiple' />
          <HeaderFilter visible />
          <Scrolling mode='virtual' />
          <LoadPanel showPane={true}  />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>

          <Item name='searchPanel' location='before'  />

          <Item location='before' locateInMenu='auto'>
            <Button
                  icon='clearformat'
                  hint={t("clearFilters")}
                  stylingMode='text'
                  onClick={clearSelections}
                />
          </Item>

            <Item location='before' locateInMenu='auto'>
                <Button
                        icon='refresh'
                        stylingMode='text'
                        hint={t("refreshList")}
                        onClick={getListFromApi}
                    />
                </Item>


          <Item location='before' name='columnChooserButton' locateInMenu='auto' />

          <Item name='exportButton' location='before' />

          <Item location='center' locateInMenu='auto'>
          <p>{t("selected")}: {selectedProductGroups.length} / {totalCount}</p>
          </Item>



          </Toolbar>

          <Column dataField='itemSubCategory' caption={t("name")}></Column>
        </DataGrid>
        </Popup>
      );
};

export default BrandsSearch;
