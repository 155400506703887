import React from 'react';

import './ContactStatus.scss';
import { useTranslation } from 'react-i18next';

export const ContactStatus = ({ text, contentClass = '', showText = true }:
{ text: string, contentClass?: string, showText?: boolean })=> {
  const {t} = useTranslation();
  return (
  <div className={`status status-contact status-${text=="1"?"commission":"terminated"} ${contentClass}`}>
    <span>{showText ? (text=="1"?t("active"):t("passive")) : ''}</span>
  </div>
)};

export const ImageStatus = ({ text, contentClass = '', showText = true }:
  { text: string, contentClass?: string, showText?: boolean })=> {
    const {t} = useTranslation();
    return (
    <div className={`status status-contact status-${text=="0"?"commission":"terminated"} ${contentClass}`}>
      <span>{showText ? (text=="0"?t("active"):t("passive")) : ''}</span>
    </div>
  )};

export const RouteStatus = ({ text, contentClass = '', showText = true }:
  { text: string, contentClass?: string, showText?: boolean }) => {
    const { t } = useTranslation();

    const getStatusClass = (status: string) => {
      switch (status) {
        case "0":
          return "waiting";  // Kırmızı nokta için
        case "1":
          return "started";  // Sarı nokta için
        case "2":
          return "completed";  // Yeşil nokta için
        default:
          return "";
      }
    };

    const getStatusText = (status: string) => {
      switch (status) {
        case "0":
          return t("waiting");
        case "1":
          return t("started");
        case "2":
          return t("completed");
        default:
          return "";
      }
    };

    return (
      <div className={`status status-contact status-${getStatusClass(text)} ${contentClass}`}>
        <span>{showText ? getStatusText(text) : ''}</span>
      </div>
    );
  };

export const RegistrationStatus = ({ text, contentClass = '', showText = true }:
  { text: string, contentClass?: string, showText?: boolean }) => {
    const { t } = useTranslation();

    const getStatusClass = (status: string) => {
      switch (status) {
        case "0":
          return "requested";  // Kırmızı nokta için
        case "1":
          return "approved";  // Sarı nokta için
        case "2":
          return "rejected";  // Yeşil nokta için
        default:
          return "";
      }
    };

    const getStatusText = (status: string) => {
      switch (status) {
        case "0":
          return t("requested");
        case "1":
          return t("approved");
        case "2":
          return t("rejected");
        default:
          return "";
      }
    };

    return (
      <div className={`status status-contact status-${getStatusClass(text)} ${contentClass}`}>
        <span>{showText ? getStatusText(text) : ''}</span>
      </div>
    );
  };

