import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import './b2b-customer-list.scss';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes,
  FilterPanel,
  FilterBuilderPopup,
  DataGridRef,
  Editing
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { ContactStatus as ContactStatusType, CustomerModel } from '../../types/crm-contact';
import { ContactStatus } from '../../components';
import { newContact, newCustomerUserModel } from '../../shared/constants';
import notify from 'devextreme/ui/notify';
import { b2bUsersListDeleteRequest, b2bUsersListRequest, b2bUsersListUpdateRequest, b2bUsersListUpdateWithCustomerRequest, b2bUsersStatusUpdateRequest, customersRequest } from '../../api/requests';
import { customerListFilterFromSession, customerListFilterToSession, customerListSelectFromSession, customerListSelectToSession } from '../../api/sessions';
import { useTranslation } from 'react-i18next';
import { useCustomerContext } from '../../contexts/customers';
import { appInfo } from '../../app-info';
import { B2BUsersPopup } from '../../components/library/b2b-users-list/popup';
import { Popup, TextBox } from 'devextreme-react';
import { confirm } from 'devextreme/ui/dialog';


export const B2BCustomerUsersList = () => {
  const { t } = useTranslation();
  const filterStatusList = ["all", "active", "passive"];
  const [userActive, setUserActive] = useState("active");
  const [rowCount, setRowCount] = useState(0);

  const [gridDataSource, setGridDataSource] = useState([]);
  const [isPanelOpened, setPanelOpened] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [newUser,setNewUser] = useState(newCustomerUserModel);
  const gridRef = useRef<DataGridRef>(null);
  const [selectedRows, setSelectedRows] = useState([]);


  const [rowData, setRowData] = useState();


  function mainRefresh(){
    b2bUsersListRequest({customerRef:0}).then((res)=>{
      setGridDataSource(res.dataSet);
      gridRef.current?.instance().endCustomLoading();

    })
  }

  useEffect(()=>{
    mainRefresh();
  },[])


  function updateFilter(){
    if(customerListFilterFromSession()=="0"){
      gridRef.current?.instance().filter(['userActive', '=', "0"]);
    }else if(customerListFilterFromSession()=="1"){
      gridRef.current?.instance().filter(['userActive', '=', "1"]);
    }else{
      gridRef.current?.instance().clearFilter()
    }

    if(customerListFilterFromSession()=="0"){
      setUserActive("passive")
    }else if(customerListFilterFromSession()=="1"){
      setUserActive("active")
    }else{
      setUserActive("all")
    }
  }



  const changePopupVisibility = useCallback((isVisble) => {
    setPopupVisible(isVisble);
    console.log("changePopupVisibility")
  }, []);

  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
    gridRef.current?.instance().navigateToRow(customerListSelectFromSession());
    console.log("changePanelOpened")
  }, [isPanelOpened]);

  const changePanelPinned = useCallback(() => {
    gridRef.current?.instance().updateDimensions();
  }, []);

  const onAddContactClick = useCallback(() => {
    setNewUser(newCustomerUserModel);
    setPopupVisible(true);
  }, []);

  const onRowClick = ({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
    setRowData(data);
    setPanelOpened(true);
    var ss =  data.combinedCode;
    console.log(ss)
    customerListSelectToSession(ss);

  };


  const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {
    const { item: selected }: { item: FilterContactStatus } = e;
    console.log(selected)
    if (selected == t("all")) {
      customerListFilterToSession("2")
      setUserActive("all");
      gridRef.current?.instance().clearFilter();
    } else if(selected==t('active')){
      gridRef.current?.instance().filter(['userActive', '=', "1"]);
      customerListFilterToSession("1")
      setUserActive("active");
    }else if(selected == t('passive')){
      gridRef.current?.instance().filter(['userActive', '=', "0"]);
      customerListFilterToSession("0")
      setUserActive("passive");
    }

  }, []);

  function getTranslatedStatusList() {
    return filterStatusList.map((status) => t(status));
  }

  const refresh = useCallback(() => {
    mainRefresh();
    gridRef.current?.instance().beginCustomLoading(t("loading"))

  }, []);



  const onSaveClick = useCallback(() => {

    b2bUsersListUpdateRequest(newUser).then((res)=>{
      if(res.resCode == 200){
        notify(t("successfull"),'success');
        mainRefresh();
        setPopupVisible(false);
        setNewUser(newCustomerUserModel)
      }else{
        notify(res.resMessage,"error")
      }
    });

  }, [newUser]);

  const handleContentReady = (e) => {
    setRowCount(e.component.totalCount());
  };

  const updateField = (key) => (value) => {
    setNewUser(prev => ({
      ...prev,
      [key]: value
    }));
    console.log(newUser.userName)
  };

  const onRowRemoving = (e) => {
    const row = e.data;

    return b2bUsersListDeleteRequest(row)
        .then((response) => {
            if (response.resCode == 200) {
                notify(t("successfull"), "success", 1000);
            } else {
                notify(response.resMessage, "error", 2500);
                mainRefresh();
                throw new Error(response.resMessage);
            }
        })
        .catch((error) => {
            console.error('Error deleting product:', error);
        });
  };

  const onSelectionChanged = (e) => {
    setSelectedRows(e.selectedRowsData);
  };

  async function deleteSelected(){
  const result = await confirm(t("areYouSureDeleting"),t("deleteProcess"));
    if (result) {
      gridRef.current?.instance().beginCustomLoading(t("loading"));
      var i = 0;
      selectedRows.forEach((row, index) => {

        b2bUsersListDeleteRequest(row).then((response)=>{
              if(response["resCode"]==200){
              }else{
                  notify(response["resMessage"],"error");
              }
              i++;
              if(i==selectedRows.length){
                  gridRef.current?.instance().endCustomLoading();
                  mainRefresh();
              }
          });
      });
    }
  }

  async function changeStatus(status){
    const result = await confirm(
      status==1?t("activeSelectedConfirm"):t("passiveSelectedConfirm"),
      status==1?t("activeSelected"):t("passiveSelected"));
      if (result) {
        gridRef.current?.instance().beginCustomLoading(t("loading"));
        var i = 0;
        selectedRows.forEach((row, index) => {

          b2bUsersStatusUpdateRequest(row,status).then((response)=>{
                if(response["resCode"]==200){
                }else{
                    notify(response["resMessage"],"error");
                }
                i++;
                if(i==selectedRows.length){
                    gridRef.current?.instance().endCustomLoading();
                    mainRefresh();
                }
            });
        });
      }
    }

  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          height='100%'
          focusedRowEnabled
          keyExpr="userRef"
          dataSource={gridDataSource}
          onRowClick={onRowClick}
          onRowRemoving={onRowRemoving}
          noDataText={t("noData")}
          onExporting={onExporting}
          onContentReady={handleContentReady}
          onSelectionChanged={onSelectionChanged}
          allowColumnReordering
          showBorders
          ref={gridRef}
          editing={{
                      mode: 'row',
                      allowDeleting: true,
                  }}>
          <Editing texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}  />

          <LoadPanel showPane={false}  />
          <SearchPanel visible placeholder={t("customerSearch")}  />
          <ColumnChooser enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />

          <Toolbar>

            <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={getTranslatedStatusList()}
                stylingMode='text'
                text={t(userActive)}
                dropDownOptions={dropDownOptions}
                useSelectMode
                onSelectionChanged={filterByStatus}
              />
            </Item>

            <Item name='searchPanel' location='before' />

            <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                text='Refresh'
                stylingMode='text'
                onClick={refresh}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before'/>

            <Item location="before">
                <DropDownButton
                    visible={selectedRows.length>0}
                    text={"("+ selectedRows.length+") "+ t("transactions")}
                    icon="menu"
                    width={"175"}
                    items={[
                      { text: t("activeSelected"), icon: 'check', action: 'activeSelected' },
                      { text: t("passiveSelected"), icon: 'minus', action: 'passiveSelected' },
                        { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                      ]}
                    onItemClick={(e) => {
                          if(e.itemData.action=="activeSelected"){
                            changeStatus(1);
                        }
                        if(e.itemData.action=="passiveSelected"){
                            changeStatus(0);
                        }
                        if(e.itemData.action=="deleteSelected"){
                            deleteSelected();
                        }
                      }}
                />
            </Item>

            <Item location='center' locateInMenu='auto'>
                {rowCount} {t("records")}
            </Item>

            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                text=''
                type='default'
                stylingMode='contained'
                onClick={onAddContactClick}
              />
            </Item>


          </Toolbar>

        <Column caption={t("status")} dataField='userActive' width={120}
                              cellRender={ContactStatus} alignment='left' ></Column>
        <Column caption={t("userName")} dataField='userName'></Column>
        <Column caption={t("email")} dataField='userEmail'></Column>
        <Column caption={t("comment")} dataField='comment'></Column>

        </DataGrid>
        {
          /*
                <ContactPanel mainRefresh={mainRefresh}  rowData={rowData} isOpened={isPanelOpened} changePanelOpened={changePanelOpened} changePanelPinned={changePanelPinned} />

          */
        }
        <B2BUsersPopup mainRefresh={mainRefresh} closePopup={changePanelOpened}  rowData={rowData!} isPanelOpened={isPanelOpened} changePanelOpened={changePanelOpened} isNewRecord={false} ></B2BUsersPopup>

        <Popup
        // Yeni user ekle
        visible={popupVisible}
        height={450}
        width={480}
        title={t("addUser")}
        onHiding={()=>{setPopupVisible(false)}}
        contentRender={() => (

          <>


            <TextBox
              label={t("userName")}
              value={newUser.userName}
              onValueChange={updateField('userName')}
            />
            <div style={{height:"10px"}}></div>

            <TextBox
              label={t("email")}
              value={newUser.userEmail}
              onValueChange={updateField('userEmail')}
            />
            <div style={{height:"10px"}}></div>


            <TextBox
              label={t("password")}
              value={newUser.userPassword}
              onValueChange={updateField('userPassword')}
            />
            <div style={{height:"10px"}}></div>

            <TextBox
              label={t("comment")}
              value={newUser.comment}
              onValueChange={updateField('comment')}
            />
            <div style={{height:"60px"}}></div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button text='Vazgeç' onClick={() => { setPopupVisible(false) }} />
                <div style={{width:10}}></div>
              <Button text='Kaydet' type='default' onClick={onSaveClick} />
            </div>


          </>

              )}

        />
      </div>
    </div>
  );
};



type FilterContactStatus = ContactStatusType | 'active';


const cellNameRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
  <div className='name-template'>
    <div>{cell.data.customerName}</div>
    <div className='position'>{cell.data.position}</div>
  </div>
);


const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Customers.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Customers.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto' };